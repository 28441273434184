import styled from 'styled-components'
import media from 'styled-media-query'
import { InfoCircle } from '@styled-icons/boxicons-regular'

export const TooltipText = styled.p`
  margin-top: 0.5rem;
  transform: translate(-10%, 0);
  padding: 0.3rem;
  font-weight: normal;
  font-size: 0.9rem;
  border-radius: 0.4rem;
  position: absolute;
  box-sizing: border-box;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  background-color: #e8e8e8;
  color: #424242;
  left: ${props => (props.singleExam ? '-12.5rem' : '-11rem;')};
  text-transform: none;
  text-align: center;

  ${media.greaterThan('huge')`
    font-size: 12px;
  `}
`

export const InfoCircleIcon = styled(InfoCircle)`
  &:hover + ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`

export const TooltipWrapper = styled.span`
  width: 100%;
  left: ${props => (props.singleExam ? '15rem' : '13.5rem')};
  top: -2px;
  position: absolute;
  display: inline-block;

  ${media.lessThan('medium')`
    display: none;
  `}

  >i {
    color: var(--pink);
    max-width: 2rem;
  }
`
