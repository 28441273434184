import React from 'react'
import PropTypes from 'prop-types'

import { EachInfoContainer } from '../shared/container'
import { InfoExam } from '../shared/text-styled'

const Methodology = ({ exam }) => {
  return (
    <EachInfoContainer noBorder>
      <InfoExam>{exam.informativeProduct.metodo}</InfoExam>
    </EachInfoContainer>
  )
}

Methodology.propTypes = {
  exam: PropTypes.object.isRequired,
}

export default Methodology
