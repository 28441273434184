import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { EachInfoSingleExamContainer } from '../shared/container'
import { Synonymies } from '../shared/text-styled'
import ExamButton from './ExamButton'
import NavButton from './NavButton'
import { ChipAns, ContentChipAns } from '../shared/ansChip'
import { CloseRound } from '@dasa-health/alma-icons'
import * as S from './styled'
import * as F from '../shared/fragments/exam-fragments'
import useIsBrazil from '../../utils/isBrazil'
import variableSubstitution from '../../utils/variableSubstitution'
import TooltipCustom from '../shared/TooltipCustom'

const MainInfo = ({ exam, data }) => {
  const isBrazil = useIsBrazil()
  const showNavButton = isBrazil
    ? exam.vertical.some(vertical => vertical.processos.some(processo => processo.id === 12))
    : exam.vertical.nome
    ? exam.vertical.some(vertical => vertical.processos.some(processo => processo.id === 12))
    : false
  const idExam = exam.id
  const linkExamNav = variableSubstitution(data.btn_link_nav_appointment, 'XX', idExam)

  return (
    <EachInfoSingleExamContainer>
      <F.SpecializationProduct exam={exam} />

      <S.ExamName>{exam.name}</S.ExamName>

      {exam.ansCover && (
        <S.ChipAnsDiv>
          <ContentChipAns>
            <ChipAns>{data.ans_label_exam_details}</ChipAns>
          </ContentChipAns>
          <TooltipCustom />
        </S.ChipAnsDiv>
      )}
      {!exam.ansCover && (
        <S.ChipAnsDiv>
          <ContentChipAns>
            <ChipAns>
              <CloseRound height={15} width={15} style={{ marginRight: 10 }} />
              Exame sem cobertura ANS
            </ChipAns>
          </ContentChipAns>
          <TooltipCustom text='Exame fora do Rol da ANS. Consulte seu plano para verificar a disponibilidade de cobertura.' />
        </S.ChipAnsDiv>
      )}

      {exam.synonymies && (
        <Synonymies>
          <Fragment>
            <S.OtherNames>{data.others_names_exam_details} </S.OtherNames>
            <S.Synonymies>{exam.synonymies.join(', ')}</S.Synonymies>
          </Fragment>
        </Synonymies>
      )}

      {data.show_schedule_exams_btn && (
        <S.ScheduleDiv>
          <ExamButton
            data={{
              btn_title_whatsapp_appointment: data.schedule_exams_btn,
              btn_msg_whatsapp_appointment: data.btn_msg_whatsapp_appointment,
              btn_link_whatsapp_appointment: 'https://api.whatsapp.com/send?phone=551130037323',
              btn_icon_whatsapp_appointment: 'whatsapp',
            }}
            exam={exam}
            noMargin={true}
          />
        </S.ScheduleDiv>
      )}

      {data.show_collection_flow && (
        <S.ScheduleDiv>
          <S.ExamSchedule>{data.title_appointment_flow}</S.ExamSchedule>

          <S.ExamScheduleDescription>{data.description_appointment_flow}</S.ExamScheduleDescription>

          <S.ScheduleBtns>
            {showNavButton && <NavButton data={data} linkBtn={linkExamNav} />}

            <ExamButton data={data} exam={exam} />
          </S.ScheduleBtns>
        </S.ScheduleDiv>
      )}
    </EachInfoSingleExamContainer>
  )
}

MainInfo.propTypes = {
  exam: PropTypes.object.isRequired,
}

export default MainInfo
