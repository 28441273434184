import React from 'react'
import PropTypes from 'prop-types'

import { EachInfoContainer } from '../shared/container'
import { InfoExam } from '../shared/text-styled'

const Documents = ({ documents, noResults }) => {
  // const theme = useContext(ThemeContext)
  return (
    <EachInfoContainer noBorder>
      {documents.length === 0 ? (
        <InfoExam>{noResults}</InfoExam>
      ) : (
        documents.map(document => (
          <InfoExam key={document.id}>
            {document.name}
            {/*  {document.url ? (
              <ExternalLink target="_blank" norel noopener href={document.url}>
                <LinkIcon size="25" color={theme.colors.secondary} />
              </ExternalLink>
            ) : null} */}
          </InfoExam>
        ))
      )}
    </EachInfoContainer>
  )
}

Documents.propTypes = {
  documents: PropTypes.arrayOf({
    id: PropTypes.number,
    name: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
}

export default Documents
