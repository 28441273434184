import React from 'react'
import PropTypes from 'prop-types'
import IconWrapper from '../shared/IconWrapper'
import { getAlmaIconByName } from '../shared/getAlmaIconByName'

import * as S from './styled'
import variableSubstitution from '../../utils/variableSubstitution'

const NavButton = ({data, linkBtn}) => {
    const txtButton = data.btn_title_nav_appointment
    const linkButton = variableSubstitution(linkBtn, '&amp;', '&')
    const iconButton = getAlmaIconByName(data.btn_icon_nav_appointment)

    return (
        <S.ScheduleExamButton href={linkButton} target="_blank">
            <S.Icon>
                <IconWrapper Icon={iconButton} size="22" />
            </S.Icon>

            {txtButton}
        </S.ScheduleExamButton>
    )
}

NavButton.propTypes = {
  data: PropTypes.object.isRequired,
}

export default NavButton
