import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'

const Tooltip = ({ tooltipText, singleExam }) => (
  <S.TooltipWrapper singleExam={singleExam}>
    <S.InfoCircleIcon size="20" />
    <S.TooltipText singleExam={singleExam}>{tooltipText}</S.TooltipText>
  </S.TooltipWrapper>
)

Tooltip.propTypes = {
  tooltipText: PropTypes.string,
  singleExam: PropTypes.bool,
}

export default Tooltip
