import React from 'react'

const ImgGeneticExam = () => {
    return (
        <svg viewBox="0 0 361 724" fill="none"
        xmlns="http://www.w3.org/2000/svg">
            <path d="M-0.000177364 516.922L-0.000177341 519H311.357C311.847 524.994 312.091 531.23 312.091 537.61V599.917H-0.000176442L-0.000175883 650.285H312.091V724H360.998V517.164C360.998 405.987 292.607 339.523 180.499 339.523C112.647 339.523 69.5657 310.569 54.7321 255.416L356.151 255.416C359.382 240.383 360.998 224.19 360.998 206.933V205.096L49.5918 205.096C49.1512 197.604 48.9064 189.918 48.9064 182.039V124.083L360.998 124.083V73.7148H48.9064V0L-0.000183105 5.76055e-07L-0.000180805 207.078C-0.000179571 318.254 68.3908 384.477 180.499 384.477C248.253 384.477 291.285 413.479 306.217 468.584H4.79752C1.61541 483.617 -0.000177555 499.714 -0.000177364 516.922Z" />
            <path d="M0.00183967 516.922L0.00184197 724H48.9085L48.9084 516.922C48.9084 430.398 95.0246 384.525 180.501 384.525C292.609 384.525 361 318.061 361 206.885L361 0.000129034L312.093 0.00012961V206.837C312.093 293.361 265.977 339.475 180.501 339.475C68.3438 339.523 0.00183844 405.746 0.00183967 516.922Z"/>
            <path d="M-0.000177364 516.922L-0.000177341 519H311.357C311.847 524.994 312.091 531.23 312.091 537.61V599.917H-0.000176442L-0.000175883 650.285H312.091V724H360.998V517.164C360.998 405.987 292.607 339.523 180.499 339.523C112.647 339.523 69.5657 310.569 54.7321 255.416L356.151 255.416C359.382 240.383 360.998 224.19 360.998 206.933V205.096L49.5918 205.096C49.1512 197.604 48.9064 189.918 48.9064 182.039V124.083L360.998 124.083V73.7148H48.9064V0L-0.000183105 5.76055e-07L-0.000180805 207.078C-0.000179571 318.254 68.3908 384.477 180.499 384.477C248.253 384.477 291.285 413.479 306.217 468.584H4.79752C1.61541 483.617 -0.000177555 499.714 -0.000177364 516.922Z" fill="#D22673"/>
            <path d="M0.00183967 516.922L0.00184197 724H48.9085L48.9084 516.922C48.9084 430.398 95.0246 384.525 180.501 384.525C292.609 384.525 361 318.061 361 206.885L361 0.000129034L312.093 0.00012961V206.837C312.093 293.361 265.977 339.475 180.501 339.475C68.3438 339.523 0.00183844 405.746 0.00183967 516.922Z" fill="#A6E1FF"/>
        </svg>
    )
}

export default ImgGeneticExam




