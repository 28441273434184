import React from 'react'
import { removeLastChar } from '@cms/utils'
import { Edit } from '@dasa-health/alma-icons'
import { Tooltip } from '@dasa-health/alma-react'
import variableSubstitution from '../../utils/variableSubstitution'
import * as S from './styled'

const CopyButton = ({ exam, data }) => {
    const textCopy = exam.genes.map(gene => removeLastChar(gene)).join(', ')
    const textTooltip = variableSubstitution(data.text_tooltip_copy_genes, "XX", exam.genes.length)

    return (
        <Tooltip 
            message={textTooltip}
            offset={10}
            placement="bottom-start"
            zIndex={9999}
            trigger='click'
        >
            <S.CopyButton
                size='sm'
                brand={true}
                onClick={() => navigator.clipboard.writeText(textCopy)}
                className="btn-dasa-genomica"
            >
                <S.CopyPasteButton float>
                    <S.CopyIcon>
                    <Edit />
                    </S.CopyIcon>
                    <S.Copy>{data.title_btn_copy_genes}</S.Copy>
                </S.CopyPasteButton>
            </S.CopyButton>
        </Tooltip>
    )
}

export default CopyButton
