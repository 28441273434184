import React, { useState } from 'react'
import { Expand, Close } from '@dasa-health/alma-icons'
import { removeLastChar } from '@cms/utils'
import * as S from './styled'
import CopyButton from './CopyButton'

const ModalGenes = ({ exam, data }) => {
    const [show, setShow] = useState(false);

    const handleOpen = () => {
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
    };
  
    return (
        <>
            <S.ViewAllButton
                size='sm'
                brand={true}
                onClick={handleOpen}
            >
                <S.ViewAllButtonContainer>
                    <S.ViewAllIcon>
                        <Expand />
                    </S.ViewAllIcon>
                    <S.ViewAll>
                        {data.title_btn_all_genes}
                    </S.ViewAll>
                </S.ViewAllButtonContainer>
            </S.ViewAllButton>

            {show && (
                <S.ModalOverlay onClick={handleClose}>
                    <S.ModalContent onClick={(e) => e.stopPropagation()}>
                        <S.ModalHeader>
                            <S.TitleModalGenes>
                                {data.title_modal_genes}
                            </S.TitleModalGenes>

                            <S.ModalBtns>
                                <CopyButton exam={exam} data={data} />
                                
                                <S.CloseButton onClick={handleClose}>
                                    <Close />
                                </S.CloseButton>
                            </S.ModalBtns>

                        </S.ModalHeader>

                        <S.ModalBody>
                            <S.ModalAllGenesContainer>
                                {exam.genes.map(item => (
                                    <S.ModalEachGenesContainer key={item.key}>
                                        <S.EachGenesName>{removeLastChar(item)}</S.EachGenesName>
                                    </S.ModalEachGenesContainer>
                                ))}
                            </S.ModalAllGenesContainer>
                        </S.ModalBody>

                    </S.ModalContent>
                </S.ModalOverlay>
            )}
        </>
      );
}

export default ModalGenes
