import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import { Download } from '@dasa-health/alma-icons'
import getDocForDownload from '../../utils/getDocForDownload'

 const DownloadButton = ({pdfsForDownload,examId}) =>{
    
    const doc = getDocForDownload(pdfsForDownload,examId);

    return (
        <S.CopyButton
                size='sm'
                brand={true}
                onClick={() => doc ? (window.open(doc.node.publicURL, "_blank")) : "" }
                className="btn-dasa-genomica"
            >
                <S.CopyPasteButton float>
                    <S.CopyIcon>
                    <Download />
                    </S.CopyIcon>
                    <S.Copy>Download</S.Copy>
                </S.CopyPasteButton>
            </S.CopyButton>
    );
}

DownloadButton.propTypes = {
    pdfsForDownload: PropTypes.array,
    examName: PropTypes.string,
  }

export default DownloadButton;