

const getDocForDownload = (arrayDoc, idExam) =>{

    const getExamId = String(process.env.GATSBY_EXAM_WITH_OPTION_DOWNLOAD).split(',')
    .find(item => item === idExam);

    if(arrayDoc){
        switch (getExamId) {
            case '42992':
                return arrayDoc[0];
            case '7806':
                return arrayDoc[0];
            default:
                break;
        }
    }
    return "";
}

module.exports = getDocForDownload;