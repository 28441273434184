import styled from 'styled-components'
import media from 'styled-media-query'
import * as A from 'react-accessible-accordion'
import { alvTokens } from '@dasa-health/alma-tokens'

export const AccordionItemButton = styled(A.AccordionItemButton)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  outline: none;
  position: relative;
  
  &.exams {
    font-size: 12px;
    font-weight: 700;
    line-height: 133%;
    letter-spacing: 1.5px;
  }

  &::after {
    display: inline-block;
    content: '';
    height: 8px;
    width: 8px;
    border-bottom: 2px solid currentColor;
    border-right: 2px solid currentColor;
    transform: rotate(45deg);
    color: rgba(22, 22, 22, 0.88);
  }

  &[aria-expanded='true'],
  &[aria-selected='true'] {
    &::after {
      transform: rotate(-135deg);
    }
  }
`

export const AccordionItemButtonTitle = styled.h2`
  font-size: 16px;
  color: ${props => props.theme.colors.palette.black};
  text-transform: ${props => (props.singleExam ? 'none' : 'uppercase')};
  font-weight: 500;
  line-height: 150%;
  margin: 0;

  ${media.lessThan('medium')`
    color: ${alvTokens.ColorPaletteGray30};
  `}
`

export const AccordionItem = styled(A.AccordionItem)`
  display: ${props => (props.hidden ? 'none' : 'block')};
  padding: 1.6rem;

  &:last-child {
    margin: 0;
    margin-bottom: 0;
  }

  ${media.lessThan('medium')`
    padding: 0;
    padding-bottom: 1.6rem;
    margin-bottom: 1.6rem;

    &:last-child {
      margin: 0;
      margin-bottom: 0;
    }
  `}
`

export const AccordionItemPanel = styled(A.AccordionItemPanel)`
  margin-top: 0.8rem;
`

export const Accordion = styled(A.Accordion)`
  background: ${alvTokens.ColorNeutral00};
  padding: 1.6rem 1.6rem 0 1.6rem;
  border-radius: 4px;

  ${media.lessThan('medium')`
    border: 1px solid rgba(22, 22, 22, 0.12);
  `}

  & > * {
    border-bottom: 1px solid #e8e8e8;
    margin-bottom: 1.6rem;
    
    &:last-child {
      border-bottom:0;
    }
  }
`
