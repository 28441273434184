import React from 'react'
import PropTypes from 'prop-types'

import { EachInfoContainer } from '../shared/container'
import * as F from '../shared/fragments/exam-fragments'

const SampleType = ({ exam, noResults }) => {
  return (
    <EachInfoContainer noBorder>
      <F.Materials exam={exam} noResults={noResults} />
    </EachInfoContainer>
  )
}

SampleType.propTypes = {
  exam: PropTypes.object.isRequired,
}

export default SampleType
