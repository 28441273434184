import React from 'react'
import PropTypes from 'prop-types'
import * as S from './styled'
import variableSubstitution from '../../utils/variableSubstitution'

const Deadline = ({ exam, data }) => {
  const deadline = variableSubstitution(data.description_deadline, "XX", exam.deadline)

  return (
    <S.InfoContainer>
      <S.ExamSpecTitle>{data.title_deadline_section}</S.ExamSpecTitle>
      <S.InfoList>
        <S.InfoListItem>
          {exam.deadline 
          ?
            deadline
          :
            data.text_no_results
          }
        </S.InfoListItem>
      </S.InfoList>
    </S.InfoContainer>
  )
}

Deadline.propTypes = {
  exam: PropTypes.object.isRequired,
}

export default Deadline
