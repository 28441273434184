import React from 'react'
import PropTypes from 'prop-types'
import IconWrapper from '../shared/IconWrapper'
import { getAlmaIconByName } from '../shared/getAlmaIconByName'
import variableSubstitution from '../../utils/variableSubstitution'

import * as S from './styled'

const ExamButton = ({data, exam, noMargin}) => {
  const txtButton = data.btn_title_whatsapp_appointment
  const examName = exam.name || ''
  const linkButton = data.btn_link_whatsapp_appointment
  const msgWhats = variableSubstitution(data.btn_msg_whatsapp_appointment, "{nome_exame}", examName)
  const msgEncoded = encodeURIComponent(msgWhats)
  const linkWhats = `${linkButton}${msgEncoded}`
  const linkWhatsEncoded = variableSubstitution(linkWhats, '&amp;', '&')
  const iconButton = getAlmaIconByName(data.btn_icon_whatsapp_appointment)

  return (
    <S.ScheduleExamButton href={linkWhatsEncoded} target="_blank" noMargin={noMargin || false}>
      <S.Icon>
        <IconWrapper Icon={iconButton} size="22" />
      </S.Icon>

      {txtButton}
    </S.ScheduleExamButton>
  )
}

ExamButton.propTypes = {
  exam: PropTypes.object.isRequired,
}

export default ExamButton