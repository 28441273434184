import React from 'react'
import PropTypes from 'prop-types'
import { EachInfoContainer } from '../shared/container'
import { Info } from '../shared/text-styled'
import * as S from './styled'
import * as F from '../shared/fragments/exam-fragments'
import variableSubstitution from '../../utils/variableSubstitution'

const Genes = ({ exam, data }) => {
  const genes = exam.showGeneCount 
                ?
                  variableSubstitution(data.description_section_analyzed_genes, "XX", exam.genes.length)
                :
                  false

  return (
    <EachInfoContainer noBorder="true">
      <S.ExamSpecTitle>{data.title_section_analyzed_genes}</S.ExamSpecTitle>

      {genes && (
        <Info margin="0 0 1.6rem">
          {genes}
        </Info> 
      )}
      
      <F.AnalyzedGenes exam={exam} data={data} />
    </EachInfoContainer>
  )
}

Genes.propTypes = {
  exam: PropTypes.object.isRequired,
}

export default Genes
