import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { EachInfoContainer } from '../shared/container'
import { InfoExam } from '../shared/text-styled'

import * as S from './styled'

const Description = ({ exam, titleSubAccordion }) => {
  return (
    <EachInfoContainer noBorder>
      <InfoExam>{exam.informativeProduct.descricao}</InfoExam>

      {exam.informativeProduct.objetivo && (
        <Fragment>
          <S.ExamSummaryTitle>{titleSubAccordion}</S.ExamSummaryTitle>
          <InfoExam>{exam.informativeProduct.objetivo}</InfoExam>
        </Fragment>
      )}
    </EachInfoContainer>
  )
}

Description.propTypes = {
  exam: PropTypes.object.isRequired,
}

export default Description
