import React from 'react'
import PropTypes from 'prop-types'

import * as Acc from 'react-accessible-accordion'
import * as S from './styled'
import Tooltip from '../tooltip'

const Accordion = props => {
  const openUids = []

  props.items.forEach(item => {
    if (item.openDesktop) {
      openUids.push(item.uuid)
    }
  })

  return (
    <S.Accordion
      data-cy="accordion"
      singleExam={props.singleExam}
      allowMultipleExpanded={true}
      allowZeroExpanded={true}
      preExpanded={openUids}
      >
      {props.items.map(item => (
        <S.AccordionItem key={item.title} uuid={item.uuid}>
          <Acc.AccordionItemHeading>
            <S.AccordionItemButton 
              singleExam={props.singleExam}
              className={props.accordionExams ? "exams" : ''}
            >
              <S.AccordionItemButtonTitle
                singleExam={props.singleExam}
              >
                {item.title}
                {item.tooltip ? (
                  <Tooltip tooltipText={item.tooltip} singleExam={props.singleExam} />
                ) : (
                  ''
                )}
              </S.AccordionItemButtonTitle>
            </S.AccordionItemButton>
          </Acc.AccordionItemHeading>
          <S.AccordionItemPanel>{item.content}</S.AccordionItemPanel>
        </S.AccordionItem>
      ))}
    </S.Accordion>
  )
}

Accordion.propTypes = {
  items: PropTypes.array.isRequired,
  singleExam: PropTypes.bool,
}

export default Accordion
