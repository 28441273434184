import React from 'react'
import PropTypes from 'prop-types'
import { EachInfoContainer } from '../shared/container'
import * as S from './styled'
import * as F from '../shared/fragments/exam-fragments'

const Preparation = ({ exam }) => {
  return (
    <EachInfoContainer noBorder>
      <S.InfoListBulleted>
        <F.Preparation exam={exam} />
      </S.InfoListBulleted>
    </EachInfoContainer>
  )
}

Preparation.propTypes = {
  exam: PropTypes.object.isRequired,
}

export default Preparation
