import React from 'react'
import PropTypes from 'prop-types'
import * as F from '../shared/fragments/exam-fragments'
import * as S from './styled'

const Diseases = ({ exam, data }) => {
  return (
    <S.InfoContainer>
      <S.ExamSpecTitle>{data.title_diseases_section}</S.ExamSpecTitle>
      <F.Diseases exam={exam} />
    </S.InfoContainer>
  )
}

Diseases.propTypes = {
  exam: PropTypes.object.isRequired,
}

export default Diseases
